import React from 'react';
import Header from './Header';

function HomePage() {
  return (
    <div className="relative min-h-screen w-full bg-white">
           <Header />
    </div>
  );
}

export default HomePage;
