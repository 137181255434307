import React, { useEffect, useRef } from 'react';

export function FlickeringGrid({ 
  className = '', 
  squareSize = 4, 
  gridGap = 4, 
  color = '#FFFFFF',  // White squares
  maxOpacity = 1, 
  flickerChance = 0.3, 
  height = window.innerHeight, 
  width = window.innerWidth 
}) {
  const canvasRef = useRef(null);
  const frameCounter = useRef(0);  // Persist frame counter across renders

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.width = width;
    canvas.height = height;

    const totalSize = squareSize + gridGap;
    const cols = Math.floor(width / totalSize);
    const rows = Math.floor(height / totalSize);

    const animate = () => {
      frameCounter.current++;  // Update ref value instead of local variable

      // Slow down flickering by updating only every 20th frame (adjust as needed)
      if (frameCounter.current % 20 === 0) {
        ctx.clearRect(0, 0, width, height);
        for (let i = 0; i < cols; i++) {
          for (let j = 0; j < rows; j++) {
            if (Math.random() < flickerChance) {
              ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * maxOpacity})`; // Ensure white squares with random opacity
              ctx.fillRect(i * totalSize, j * totalSize, squareSize, squareSize);
            }
          }
        }
      }
      
      requestAnimationFrame(animate);
    };

    animate();
    
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [squareSize, gridGap, color, maxOpacity, flickerChance, height, width]);

  return <canvas ref={canvasRef} className={className} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }} />;
}

export default FlickeringGrid;  // Optional: Add default export
