import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Logo from '../assets/images/logo512.png';

const Header = () => {
    return (
        <header className="header" style={{ backgroundColor: '#000', padding: '20px' }}>
            <div className="flex items-center">
                <span style={{ color: 'white', fontSize: '0.5rem', fontWeight: '500' }}></span>
            </div>

            <div className="flex justify-center items-center" style={{ marginBottom: '2cm', marginLeft: '1cm' }}>
                <Link to="/newpage">
                    <button 
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors" 
                        style={{ transform: 'scale(1.3)' }}
                    >
                        Click Me
                    </button>
                </Link>
            </div>

            <div className="header-content">
                <h1 style={{ color: 'white' }}>Welcome to Cseszko.com</h1>
                <p style={{ color: 'white' }}>Shaping the Digital World</p>
                <p style={{ color: 'white' }}>Under construction and more exciting things coming soon</p>
                <img src={Logo} alt="Main Logo" className="main-logo" />
            </div>
        </header>
    );
};

export default Header;
