import React from 'react';
  import { Link } from 'react-router-dom';
  import './Navheader.css';
  import CseszkoLogo from '../assets/images/cseszko.png';




  import FlickeringGrid from '../components/FlickeringGrid';
  
const Navheader = () => {
  return (
    <nav className="nav-header" style={{ backgroundColor: '#1e40af', padding: '1rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
        <div style={{ position: 'relative' }}>
          <img src={CseszkoLogo} alt="Cseszko Logo" style={{ height: '1.5cm', marginLeft: '5cm', filter: 'brightness(0) invert(1)', position: 'relative', zIndex: 1 }} />
          <div style={{ position: 'absolute', top: 0, left: '5cm', height: '1.5cm', width: '1.5cm' }}><FlickeringGrid /></div>
        </div>
        <span style={{ color: 'White', fontSize: '1.2rem', fontWeight: '500', marginLeft: '1rem' }}>innovating the future with new projects</span>
      </div>
      <div className="nav-links" style={{ marginLeft: '30cm' }}>
        <Link to="/" className="nav-link" style={{ color: 'white' }}>Home</Link>
        <Link to="/login" className="nav-link" style={{ color: 'white' }}>Login</Link>
      </div>
    </nav>
  );
};
export default Navheader;