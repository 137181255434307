import React from 'react';
import { Link } from 'react-router-dom';
import Navheader from './Navheader';
import './NewPage.css';

function NewPage() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-4xl font-bold">Welcome to the New Page!</h1>
      <p className="text-lg mt-4">This is where the magic happens 🎉</p>
      
      <Link to="/">
        <button className="mt-6 px-6 py-3 bg-blue-500 text-white font-bold rounded-lg shadow-lg hover:bg-blue-600 transition">
          Home
        </button>
      </Link>
    </div>
  );
}

export default NewPage;
