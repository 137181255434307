import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Views/Homepage';
import Navheader from './Views/Navheader';
import NewPage from './Views/NewPage'; // Import NewPage

function App() {
  return (
    <Router>
      <Navheader />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/newpage" element={<NewPage />} /> {/* Add this route */}
      </Routes>
    </Router>
  );
}

export default App;
